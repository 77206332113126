.messages-page {
  background-color: #1e1e1e;
  color: #e4e6eb;
  min-height: 100vh;
  padding: 20px;
}

.messages-list {
  display: flex;
  flex-direction: column;
}

.message-card {
  background-color: #242526;
  margin-bottom: 15px;
  border: none;
  border-radius: 8px;
  color: #e4e6eb;
  max-width: 80%;
}

.message-card.customer {
  background-color: #3a3b3c;
  margin-left: auto;
}

.message-card.coach {
  background-color: #242526;
  margin-right: auto;
}

.message-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.message-header strong {
  color: #ffffff;
}

.message-date {
  font-size: 0.8em;
  color: #b0b3b8;
}

.message-card p {
  margin: 0;
  color: #e4e6eb;
}

.message-card img {
  max-width: 100%;
  border-radius: 5px;
  margin-top: 10px;
} 