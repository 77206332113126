// Here you can add other styles

button:focus {
  outline: 0;
}

body {
  background: #222222 !important;
}
// MONITORING //
#coach td {
  vertical-align: middle;
}
#girddata td {
  padding: 7px;
}
#girddata tr:nth-child(2) td:not(:first-child),
#girddata tr:nth-child(3) td:not(:first-child) {
  vertical-align: middle;
  text-align: center;
  font-weight: 700;
  font-size: 24px;
}
#girddata tr:first-child td,
#girddata tr td:first-child {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}
#girddata tr:first-child td {
  font-weight: 300;
}

#style-1 ::-webkit-scrollbar {
  width: 6px;
}

#style-1::-webkit-scrollbar-track {
  background: green;
}

#style-1 ::-webkit-scrollbar-thumb {
  background-color: #20a8d8;
  border-radius: 10px;
}
.account {
}
li:hover {
  cursor: default;
}
.list {
  width: 100%;
  position: relative;
  float: left;
  display: inline-block;
  background-color: transparent;
  border-radius: 5px;
}

.list ul {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: (45px * 5 - 45px / 3);
}
.listTab ul {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: (45px * 8.45 - 45px / 3);
}

.list li {
  list-style: none;
  padding: 0 (45px/2);
  padding-left: 0px;
  line-height: 45px - 1;
  cursor: pointer;

  &:last-child {
    border-bottom-width: 0;
  }

  &:nth-child(5) {
    & ~ :last-child {
      position: relative;
      z-index: 1;
    }
    & ~ :nth-last-child(2):after {
      content: "";
      cursor: s-resize;
      absolute: left right bottom;

      height: 45px;
    }
  }
}

// END MONITORING //
// REACT QUILL //
.ql-snow .ql-editor h1 {
  font-size: 1em !important;
}
.ql-snow .ql-editor img {
  width: 200px;
  position: relative;
  left: 35%;
}
.ql-snow .ql-editor h1:only-of-type {
  padding-top: 25px !important;
  border-top: #929292 1px solid !important;
  padding-bottom: 25px !important;
  border-bottom: #929292 1px solid !important;
  margin-bottom: 10px;
}
.ql-snow .ql-editor h1:first-of-type {
  margin-top: 5px;
  padding-top: 25px;
  border-top: black 1px solid;
}
.ql-snow .ql-editor h1:last-of-type {
  padding-bottom: 25px;
  border-bottom: black 1px solid;
  margin-bottom: 25px;
}

.ql-container.ql-snow {
  height: auto;
}
.ql-editor {
  height: 40vw !important;

  overflow-y: scroll !important;
}
//  END REACT QUILL //
.mainImage {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.custom-main {
  background: #222222 !important;
  padding-top: 30px !important;
  width: 100%;
  padding-right: 30px;
  padding-left: 30px;
  margin-right: auto;
  margin-left: auto;
}

.custom-dark {
  background: #2f353a !important;
  border-color: #121517 !important;
}

.custom-text-muted {
}

.custom-bg-warning {
  background-color: #ff8800 !important;
  border-color: #cc6d00 !important;
}

.custom-nav-link {
  color: #73818f !important;
}

.custom-nav-link:hover {
  color: white !important;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2.25' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E") !important;
}

.custom-pagination {
  background: transparent !important;
  background-color: transparent !important;
}

.light-text {
  color: white !important;
}

.light-textk:hover {
  color: white !important;
}

/**
 * <div class="react-tags">
 *   <div class="react-tags__selected">
 *     <button class="react-tags__selected-tag">
 *       <span class="react-tags__selected-tag-name" />
 *     </button>
 *   </div>
 *   <div class="react-tags__search">
 *     <div class="react-tags__search-wrapper">
 *       <input class="react-tags__search-input" />
 *       <div />
 *     </div>
 *     <div class="react-tags__suggestions">
 *       <ul>
 *         <li class="is-active">
 *           <mark />
 *         </li>
 *         <li class="is-disabled">
 *           <mark />
 *         </li>
 *       </ul>
 *     </div>
 *   </div>
 */
.react-tags {
  position: relative;
  padding: 6px 0 0 6px;
  border: 1px solid #d1d1d1;
  border-radius: 1px;

  /* shared font styles */
  font-size: 1em;
  line-height: 1.2;

  /* clicking anywhere will focus the input */
  cursor: text;
}

.react-tags.is-focused {
  border-color: #b1b1b1;
}

.react-tags__selected {
  display: inline;
}

.react-tags__selected-tag {
  display: inline-block;
  box-sizing: border-box;
  margin: 0 6px 6px 0;
  padding: 6px 8px;
  border: 1px solid #d1d1d1;
  border-radius: 2px;
  background: #f1f1f1;

  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
}

.react-tags__selected-tag:after {
  content: "\2715";
  color: #aaa;
  margin-left: 8px;
}

.react-tags__selected-tag:hover,
.react-tags__selected-tag:focus {
  border-color: #b1b1b1;
}

.react-tags__search {
  display: inline-block;

  /* match tag layout */
  padding: 7px 2px;
  margin-bottom: 6px;

  /* prevent autoresize overflowing the container */
  max-width: 100%;
}

@media screen and (min-width: 30em) {
  .react-tags__search {
    /* this will become the offsetParent for suggestions */
    position: relative;
  }
}

.react-tags__search-input {
  /* prevent autoresize overflowing the container */
  max-width: 100%;

  /* remove styles and layout from this element */
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;

  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
}

.react-tags__search-input::-ms-clear {
  display: none;
}

.react-tags__suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 6;
}

@media screen and (min-width: 30em) {
  .react-tags__suggestions {
    width: 240px;
  }
}

.react-tags__suggestions ul {
  margin: 4px -1px;
  padding: 0;
  list-style: none;
  background: white;
  border: 1px solid #d1d1d1;
  border-radius: 2px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.react-tags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 6px 8px;
}

.react-tags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}

.react-tags__suggestions li:hover {
  cursor: pointer;
  background: #eee;
}

.react-tags__suggestions li.is-active {
  background: #b7cfe0;
}

.react-tags__suggestions li.is-disabled {
  opacity: 0.5;
  cursor: auto;
}
